<template>
    <v-row class="ma-0 pa-0">
        <v-col>
            <v-progress-linear indeterminate v-if="Loading"></v-progress-linear>
            <div class="headline">
                <div style="display:inline">
                    <span v-if="Bill.BillType =='HB'">House Bill</span>
                    <span v-else-if="Bill.BillType =='HC'">House Commemoration</span>
                    <span v-else-if="Bill.BillType =='HCR'">House Concurrent Resolution</span>
                    <span v-else-if="Bill.BillType =='HJR'">House Joint Resolution</span>
                    <span v-else-if="Bill.BillType =='HR'">House Resolution</span>
                    <span v-else-if="Bill.BillType =='SB'">Senate Bill</span>
                    <span v-else-if="Bill.BillType =='SC'">Senate Commemorations</span>
                    <span v-else-if="Bill.BillType =='SCR'">Senate Concurrent Resolution</span>
                    <span v-else-if="Bill.BillType =='SJR'">Senate Joint Resolution</span>
                    <span v-else-if="Bill.BillType =='SR'">Senate Resolution</span>
                    <span v-else-if="Bill.BillType =='SRD'">Senate Resolution of Disapproval</span>
                    <span v-else-if="Bill.BillType =='HRD'">House Resolution of Disapproval</span>
                    {{Bill.BillNumberOnly}}
                </div>
            </div>
            <v-row>
                <v-col cols="12">
                    <div><b>Title:</b> {{Bill.Title}}</div>
                </v-col>
            </v-row>
            <div>
                <v-card flat>
                    <v-card-text>
                        <v-row v-for="item in ActionLogs" pa-2 dense style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                                <v-col cols="auto" pa-2>
                                    <div class="text--primary">
                                        <span v-if="item.JournalPage != null">
                                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf#page=' + (item.JournalPage - item.StartPageNumber)" target="_blank">
                                                {{item.ActionDate | FormatDates}}
                                            </a>
                                        </span>
                                        <span v-else>
                                            <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf#page=1'" target="_blank">
                                                {{item.ActionDate | FormatDates}}
                                            </a>
                                        </span>
                                    </div>
                                </v-col>
                                <v-col cols="9">
                                    <div class="text--primary">
                                        <span v-if="item.StatusText == null">{{item.Description}}</span>
                                        <span v-if="(item.StatusText =='Do Pass' || item.StatusText =='Tabled' || item.ShowCommitteeName) && item.ActionCommittee">
                                            <router-link :to="'/Session/Committee/'+item.ActionCommittee.ActionCommitteeId+'/Detail'">
                                                {{item.ActionCommittee.FullName}}
                                            </router-link>
                                        </span>
                                        <span v-else-if="(item.StatusText =='Do Pass' || item.StatusText =='Tabled' || item.ShowCommitteeName) && item.ConferenceCommittee">
                                            <router-link :to="'/Session/ConferenceCommittees/'+Bill.SessionId">
                                                Conference Committee {{item.ConferenceCommittee.Name}}
                                            </router-link>
                                        </span>
                                        <span v-if="item.StatusText == 'Signed by the Governor' || item.StatusText =='Delivered to the Governor'">
                                            {{item.StatusText}} on {{item.ActionDate2 | FormatDatesLongDay}}
                                            <span v-if="item.JournalPage > 0 && item.ActionCommittee && item.ActionCommittee.Body && item.ActionCommittee.FullBody">
                                                {{item.ActionCommittee.Body }}.J. {{item.JournalPage}}
                                            </span>
                                        </span>
                                        <span v-else-if="item.ShowPassed ==true|| item.ShowFailed ==true">
                                            {{item.StatusText}}<span v-if="item.AssignedCommittee && item.ShowAssignedCommittee"><router-link :to="'/Session/Committee/'+item.AssignedCommittee.AssignedCommitteeId+'/Detail'"> {{item.AssignedCommittee.FullName}}</router-link></span><span v-if="item.Result == 'P'">, Passed</span><span v-else-if="item.Result == 'F'">, Failed</span><span v-else-if="item.Result == 'D'">, Failed for lack of a second</span><span v-else-if="item.Result == 'W'">, Withdrawn</span><span v-if="item.StatusText != 'Certified uncontested, placed on consent' && item.Vote">, </span>
                                            <router-link :to="'/Session/Vote/' + item.Vote.VoteId" v-if="item.StatusText != 'Certified uncontested, placed on consent' && item.Vote">YEAS&nbsp;{{item.Vote.Yeas}}, NAYS&nbsp;{{item.Vote.Nays}}.</router-link>
                                            <span v-if=" item.JournalPage > 0 && item.ActionCommittee && item.ActionCommittee.FullBody">
                                                {{item.ActionCommittee.Body }}.J. {{item.JournalPage}}
                                            </span>
                                        </span>
                                        <span v-else>
                                            <span v-if="item.StatusText == 'Vetoed for Style and Form' || item.StatusText == 'Vetoed by the Governor' || item.StatusText == 'Line Item Veto'">
                                                <router-link :to="'/Session/BillReports/VetoedByGovernor/' + Bill.SessionId">
                                                    {{item.StatusText}}
                                                </router-link>
                                            </span>
                                            <span v-else>{{item.StatusText}}</span>
                                            <span v-if="item.AssignedCommittee && item.ShowAssignedCommittee">
                                                <router-link :to="'/Session/Committee/'+item.AssignedCommittee.AssignedCommitteeId+'/Detail'"> {{item.AssignedCommittee.FullName}}</router-link>
                                            </span>
                                            <span v-if="item.JournalPage > 0 && item.ActionCommittee && item.ActionCommittee.FullBody">
                                                {{item.ActionCommittee.Body }}.J. {{item.JournalPage}}
                                            </span>
                                        </span>
                                        <span>
                                            <span v-if="item.Amendment && item.Amendment.DocumentId">
                                                <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/Amendment/' + item.Amendment.DocumentId + '.pdf'"> Amendment {{item.Amendment.Filename}}</a>
                                            </span>
                                        </span>
                                    </div>
                                </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: 'BillStat',
        props: ['Bill', 'ActionLogs'],
        components: {},
        methods: { },
        data: function () {
            return {
                Loading: false,
                actionHeaders: [
                    { text: 'Date', value: 'ActionDate', width: '15ch' },
                    { text: 'Action', value: 'StatusText', },
                ],
            }
        },
        mounted: function () {
        },
        watch: {

        },
        computed: {
        }
    };
</script>
<style></style>