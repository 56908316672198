<template>
    <v-flex xs12 id="LRCGoogleSearchFlex" v-if="SearchOn">
        <div v-if="SessionFilter && Session" id="LRCGoogleSearch">
            <v-text-field :label="label"
                          clearable
                          v-model.trim="SearchText"
                          :loading="startedSearch"
                          v-on:keyup.enter="searchGoogle">
                <template v-slot:append>
                    <v-btn small icon @click="searchGoogle">
                        <v-icon>{{searchIcon}}</v-icon>
                    </v-btn>
                </template>
            </v-text-field>

            <gcse:searchresults-only v-if="smallSearchResults" :as_sitesearch="siteSearch" :resultsUrl="fullUrl" :gname="currentRoute" :as_oq="'inurl:Year=' +Session.Year"></gcse:searchresults-only>
        </div>
        <div v-else id="LRCGoogleSearch">
            <v-text-field :label="label"
                          clearable
                          v-model.trim="SearchText"
                          :loading="startedSearch"
                          v-on:keyup.enter="searchGoogle">
                <template v-slot:append>
                    <v-btn small icon @click="searchGoogle">
                        <v-icon>{{searchIcon}}</v-icon>
                    </v-btn>
                </template>
            </v-text-field>

            <gcse:searchresults-only v-if="smallSearchResults" :as_sitesearch="siteSearch" :resultsUrl="fullUrl" :gname="currentRoute"></gcse:searchresults-only>
        </div>
    </v-flex>
</template>
<script>
    import { mdiMagnify } from '@mdi/js';
    export default {
        name: 'GoogleSearch',
        props: ['SessionId'],
        components: {},
        methods: {
            CreateScript() {
                let GoogleSearchScript = document.getElementById('google-search');
                if (GoogleSearchScript) {
                    GoogleSearchScript.remove();
                }
                var cx = '012382400048732927914:vhsa93fkwl4';
                var gcse = document.createElement('script', { id: 'google-search' });
                gcse.id = 'google-search';
                gcse.type = 'text/javascript';
                gcse.async = true;
                gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
                var s = document.getElementsByTagName('script')[0];
                if (s) {
                    s.parentNode.insertBefore(gcse, s);
                }
                if (this.SearchOn) {
                    this.SearchOn = false;
                    var comp = this;
                    this.$nextTick().then(function () {
                        comp.SearchOn = true;
                    });
                }
            },
            searchGoogle: function (item) {
                this.startedSearch = true;
                const StatuteRegex = /\d{1,2}[A-Za-z]{0,1}[-](\d{1,2}[A-Za-z]{0,1})[-]?([0-9A-Za-z]*)?\s*(\([0-9A-Za-z]+\))?(\.[0-9A-Za-z]+)?/g;
                const ConstitutionRegex = /\d{1,2}[A-Za-z]{0,1}[-](\d{1,2}[A-Za-z]{0,1})[-]?([0-9A-Za-z]*)?\s*(\([0-9A-Za-z]+\))?(\.[0-9A-Za-z]+)?/g;
                const AdministrativeRegex = /\d{1,2}:(\d{1,2})(?::(\d{1,2})([a-zA-Z])?)?(?:\.(\d{1,2}))?(?::(\d{1,2})([a-zA-Z])?)?(?:\.(\d{1,2}))?/g;
                const Session_LawsRegex = /\d{1,2}?/g;
                this.smallSearchResults = false;
                this.$forceUpdate();
                let UpperSearchText = ((this.SearchText || "").toUpperCase() || "");
                if (this.currentRoute.includes('Constitution') && UpperSearchText.match(ConstitutionRegex)) {
                    this.$router.push('/Constitution/' + this.SearchText);
                    //fetch('/api/Statutes/ArticleToId/' + this.SearchText, {
                    //    method: "GET",
                    //    credentials: "include",
                    //    headers: {
                    //        "Content-Type": "application/json"
                    //    },
                    //}).then(res => {
                    //    this.startedSearch = false;
                    //    this.loading = false;
                    //    if (res.status === 200 || res.status === 201) {
                    //        return res.json().then(r => {
                    //            this.$router.push('/Constitution/' + r);
                    //        });
                    //    }
                    //    else {
                    //        this.performSearch();
                    //        return null;
                    //    }
                    //    throw new Error(res.statusText);
                    //}).catch(error => {
                    //    throw new Error(error);
                    //});
                }
                else if (this.currentRoute.includes('Administrative') && UpperSearchText.match(AdministrativeRegex)) {
                    fetch('/api/Rules/RuleToId/' + this.SearchText, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.startedSearch = false;
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                            this.performSearch();
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                        if (this.items != null) {
                            window.location = '/Rules/Administrative/' + this.items;
                        } else {
                            this.performSearch();
                        }
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else if (this.currentRoute.includes('Session_Laws') && UpperSearchText.match(Session_LawsRegex)) {
                    fetch('/api/SessionLaws/ChapterToId/' + this.SearchText + '?SessionId=' + this.Session.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.startedSearch = false;
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                            this.performSearch();
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.items = EncodeDatetimes(response);
                        if (this.items != null) {
                            window.location = '/Statutes/Session_Laws/Chapter/' + this.items;
                        } else {
                            this.performSearch();
                        }
                    }).catch(error => {
                        throw new Error(error);
                        this.performSearch();
                    });
                }
                else if (this.currentRoute.includes('Statutes') && UpperSearchText.match(StatuteRegex)) {
                    this.$router.push('/Statutes/' + this.SearchText);
                    //fetch('/api/Statutes/StatuteToId/' + this.SearchText, {
                    //    method: "GET",
                    //    credentials: "include",
                    //    headers: {
                    //        "Content-Type": "application/json"
                    //    },
                    //}).then(res => {
                    //    this.startedSearch = false;
                    //    this.loading = false;
                    //    if (res.status === 200 || res.status === 201) {
                    //        return res.json().then(r => {
                    //            this.$router.push('/Statutes/' + r);
                    //        });
                    //    }
                    //    else {
                    //        this.performSearch();
                    //        return null;
                    //    }
                    //    throw new Error(res.statusText);
                    //}).catch(error => {
                    //    throw new Error(error);
                    //});
                }
                else {
                    this.performSearch();

                }
            },
            performSearch() {
                if (this.$route.query.q != this.SearchText) {
                    this.$router.push({ path: this.currentRoute, query: { q: this.SearchText } });
                    this.CreateScript();
                }
                this.smallSearchResults = true;
                this.startedSearch = false;
            },
            LoadData() {
                this.loading = true;
                if (this.SessionId) {
                    fetch('/api/Sessions/' + this.SessionId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.loading = false;
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
                else {
                    fetch('/api/Sessions/Current', {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201) {
                            return res.json();
                        }
                        else {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response => {
                        this.Session = EncodeDatetimes(response);
                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },

        },
        data: function () {
            return {
                searchIcon: mdiMagnify,
                startedSearch: false,
                smallSearchResults: false,
                SearchText: '',
                searchURL: '',
                SearchOn: true,
                loading: false,
                Session: {},
                Session1: true,
                SessionFilter: false,
                fullUrl: window.location.origin + "/" + this.currentRoute,
                urlToSearch: [
                    {
                        route: '/References/ProgramEvaluation',
                        site: 'sdlegislature.gov/docs/Reports/ProgramEvaluations/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/ProgramEvaluations/*.pdf'
                    },
                    {
                        route: '/References/IssueMemos',
                        site: 'sdlegislature.gov/docs/referencematerials/IssueMemos/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/IssueMemos/*.pdf'
                    },
                    {
                        route: '/References/PerformanceReports',
                        site: 'sdlegislature.gov/docs/Reports/LPC/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/PerformanceReports/*.pdf'
                    },
                    {
                        route: '/References/RequiredReports',
                        site: 'sdlegislature.gov/docs/referencematerials/requiredreports/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/RequiredReports/*.pdf'
                    },
                    {
                        route: '/Rules/Registers',
                        site: 'sdlegislature.gov/docs/rules/Register/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/Registers/*.pdf'
                    },
                    {
                        route: '/Rules/Administrative',
                        site: 'sdlegislature.gov/Rules/*',
                        newsite: 'sdlegislature.gov/api/Rules/Rule/*.html',
                    },
                    {
                        route: '/Statutes',
                        site: 'sdlegislature.gov/Statutes/*',
                        newsite: 'sdlegislature.gov/api/Statutes/*.html',
                    },
                    {
                        route: '/Constitution',
                        site: 'sdlegislature.gov/Constitution',
                        newsite: 'sdlegislature.gov/api/Statutes/*.html',
                    },
                    {
                        route: '/Statutes/Session_Laws',
                        site: 'sdlegislature.gov/statutes/Session_Laws/DisplayChapter.aspx',
                        includeSession: true,
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/SessionLaws/*.html',

                    },
                    {
                        route: '/Session/Bills',
                        site: 'mylrc.sdlegislature.gov/api/Documents/Bill/*.pdf?Year=*',
                        includeSession: true,
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/Bill/*.pdf?Year=*',
                    },
                    {
                        route: '/Session/TextSearch',
                        site: 'mylrc.sdlegislature.gov/api/Documents/Bill/*.pdf',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/Bill/*.pdf',

                    },
                    {
                        route: '/Interim/Archived',
                        site: 'sdlegislature.gov/docs/interim/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/*.html',

                    },
                    {
                        route: '/Session/Archived',
                        site: 'mylrc.sdlegislature.gov/api/Documents/Bill/*',
                        newsite: 'mylrc.sdlegislature.gov/api/Documents/Bill/*.pdf?Year=*',
                    },
                ],
            }
        },
        mounted: function () {
            this.CreateScript();
            this.SearchText = this.$route.query.q;
            this.LoadData();
        },
        watch: {
            SessionId: function () {
                this.Session1 = !this.Session1;
            },
            currentRoute: function () {
                var comp = this;
                this.$nextTick().then(function () {
                    comp.CreateScript();
                });
            },
            siteSearch: function () {
                var comp = this;
                this.$nextTick().then(function () {
                    comp.CreateScript();
                });
            },
            smallSearchResults: function () {
                var comp = this;
                this.$nextTick().then(function () {
                    comp.CreateScript();
                });
            }
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            siteSearch: function () {
                if (this.SessionId != null) {
                    this.SessionFilter = true;
                } else {
                    this.SessionFilter = false;
                }
                var res = this.urlToSearch.filter(x => this.currentRoute.includes(x.route));
                if (res.length > 0) {
                    return res[0].site;
                } else {
                    return "*";
                }
            },
            label: function () {
                if (this.currentRoute) {

                    if (this.currentRoute.includes('Codified_Laws') || this.currentRoute.includes('Constitution')) {
                        return "Go To:(1-1-1) or Google Search"
                    } else if (this.currentRoute.includes('Session_Laws')) {
                        return "Go To:(1) or Google Search"

                    } else if (this.currentRoute.includes('Administrative')) {
                        return "Go To:(01:15) or Google Search"
                    } else if (this.currentRoute.includes('Session/Archived')) {
                        return "Google Search all previous session bills"
                    }
                    else if (this.currentRoute.includes('Statutes')) {
                        return "Go To:(1-1-1) or Google Search"
                    }
                    return "Google Search";

                }
                return "Google Search";

            }
        }
    };
</script>
<style></style>