<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-row class="ma-0">
                <div class="headline">Staff Listing</div>
            </v-row>
            <v-layout row wrap>
                <v-tabs show-arrows>
                    <v-tab v-for="i in tabs"
                           :key="i"
                           @click="filterDepartment(i)">
                        {{ i }}
                    </v-tab>
                </v-tabs>
            </v-layout>

            <v-layout row wrap>
                <v-flex pb-5 offset-md-3 offset-sm="0" cols="12" v-if="filterValue == 'All' && Director != null">
                    <PersonCard :expand="true" :UserProp="Director"></PersonCard>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex v-for="i in Staff" :key="i.UserId" :class="sizeOfColumn(i)">
                    <PersonCard :expand="true" :UserProp="i"></PersonCard>
                </v-flex>
            </v-layout>
        </v-col>
    </v-row>
</template>
<script>
    import { DirectorHead, It, Operations, Fiscal, Research } from '@/utils'

    const PersonCard = () => import("@/Components/PersonCard.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    export default {
        name: 'Listing',
        components: {
            PersonCard, Sidebar
        },
        methods: {
            LoadData()
            {
                fetch('/api/Users', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    this.items = EncodeDatetimes(response);
                    this.Director = DirectorHead(this.items);
                }).catch(error =>
                {
                    throw new Error(error);
                });
            },
            filterDepartment(item)
            {
                this.filterValue = item;
            },
            fieldSorter(fields)
            {
                return function (a, b)
                {
                    return fields
                        .map(function (o)
                        {
                            var dir = 1;
                            if (o[0] === '-')
                            {
                                dir = -1;
                                o = o.substring(1);
                            }
                            if (a[o] > b[o]) return dir;
                            if (a[o] < b[o]) return -(dir);
                            return 0;
                        })
                        .reduce(function firstNonZeroValue(p, n)
                        {
                            return p ? p : n;
                        }, 0);
                };
            },
            sizeOfColumn(i)
            {
                if (i.StaffGroupId == 1 && this.filterValue != 'All')
                {
                    return 'md6 offset-md-3 py-4';
                } 
                else
                {
                    return 'md6 py-4';
                }
            },
        },
        data: function ()
        {
            return {
                items: [],
                tabs: ['All', 'Research & Legal', 'Fiscal Analysis', 'Operations', 'IT'],
                filterValue: 'All',
                Director: null,
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Staff: function ()
            {
                if (this.items.length > 0)
                {
                    if (this.filterValue == 'All')
                    {
                        return this.items
                            .filter(x=>x.UserId != (this.Director || {}).UserId && x.UserId != null)
                            .sort(this.fieldSorter(['LastName']));
                    }
                    else if (this.filterValue == 'Fiscal Analysis')
                    {
                        return Fiscal(this.items, true);
                    }
                    else if (this.filterValue == 'Research & Legal')
                    {
                        return Research(this.items, true);
                    }
                    else if (this.filterValue == 'IT')
                    {
                        return It(this.items, true);
                    }
                    else if (this.filterValue == 'Operations')
                    {
                        return Operations(this.items, true);
                    }
                }
                else
                {
                    return {};
                }
            },
            Title: function ()
            {
                let title = 'Staff Listing';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };
        }
    };
</script>
<style></style>