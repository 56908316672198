<template>
    <v-card flat>
        <div class="headline">
            {{filteredReportTypes.name}}
        </div>
        <v-card-text>
            <div v-for="y in Years">
                <div>Fiscal Year {{y}}</div>
                <FilterableDataTable :headers="filteredHeaders"
                              disable-pagination
                              hide-default-footer
                              :items="Documents(y)"
                              :key="tableKey">
                    <template v-slot:item.Date="{ item }">
                        <a rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentType + '/' + item.DocumentId + '.pdf'">{{DatePretty(item.DocumentDate)}}</a>
                    </template>
                    <template v-slot:item.Type="{ item }">
                        <span v-if="item.DocumentTypeId == 33">Letter</span>
                        <span v-if="item.DocumentTypeId == 34">Memo</span>
                        <span v-if="item.DocumentTypeId == 36">Report</span>
                    </template>
                </FilterableDataTable>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import { DateTime } from 'luxon';
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'BudgetReportCard',
        components: {
            FilterableDataTable
        },
        methods: {
            LoadData() {
                fetch('/api/Documents/DocumentType?Type=37&Type=40&Type=39&Type=33&Type=34&Type=36&Type=38', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            Documents(year) {
                return this.items.filter(x => x.FiscalYear == year && this.filteredReportTypes.DocTypeId.includes(x.DocumentTypeId));
            },
            DatePretty(date) {
                return DateTime.fromJSDate(date).toFormat('MM/dd/yyyy');
            },
        },
        data: function () {
            return {
                headers: [
                    { text: 'Date', value: 'Date', type: 'All', filterable: false, width: '15ch' },
                    { text: 'Title', value: 'Title', type: 'ExceptLettersOfIntent', filterable: false, width: '*' },
                    { text: 'Type', value: 'Type', type: 'LettersOfIntent', filterable: false, width: '15ch' },
                    { text: 'Department', value: 'Department', type: 'LettersOfIntent', filterable: false },
                    { text: 'Description', value: 'Description', type: 'All', filterable: false, width: '50ch' },

                ],
                ReportTypes: [
                    {
                        name: 'Government Operations and Audit Documents', route: '/Budget/GOACDocuments', DocTypeId: [37]
                    },
                    {
                        name: 'General Fund Reversions', route: '/Budget/GeneralFundReversions', DocTypeId: [40]
                    },
                    {
                        name: 'Personal Services Documents', route: '/Budget/PersonalServiceDocuments', DocTypeId: [39]
                    },
                    {
                        name: 'Letters of Intent & Associated Documents', route: '/Budget/LettersOfIntent', DocTypeId: [33, 34, 36]
                    },
                    {
                        name: 'Bureau of Finance & Management (BFM) Reports', route: '/Budget/BFMReports', DocTypeId: [38]
                    },
                    {
                        name: 'Staff By Budget Agency Assignment', route: '/Staff/BudgetAgency'
                    },
                    {
                        name: 'Legislative Budget Handbook', route: 'LegislativeBudgetHandbook.pdf'
                    }
                ],
                items: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
            filteredReportTypes: function () {
                return this.ReportTypes.filter(x => x.route == this.currentRoute)[0];
            },
            tableKey: function ()
            {
                return this.ReportTypes.filter(x => x.route == this.currentRoute)[0].DocTypeId.toString();
            },
            filteredHeaders: function () {
                if (this.currentRoute.includes('LettersOfIntent')) {
                    return this.headers.filter(x => x.type != 'ExceptLettersOfIntent');
                } else {
                    return this.headers.filter(x => x.type != 'LettersOfIntent');
                }
            },
            Years: function () {
                if (this.items != undefined) {
                    var docDates = this.items.filter(x => this.filteredReportTypes.DocTypeId.includes(x.DocumentTypeId)).map(a => a.FiscalYear);
                    if (docDates.length > 0) {
                        var unique = [...new Set(docDates)].sort().reverse();
                        //this.Year = unique[0];
                        return unique;
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            },
        }
    };
    </script>
<style></style>