<template>
    <MemberCard :expand="true" :Member="Member"></MemberCard>
</template>
<script>
    import moment from 'moment'
    const MemberCard = () => import("@/Components/MemberCard.vue");

    export default {
        name: 'Detail',
        props: ['SessionMemberId'],
        components: {
            MemberCard
        },
        methods: {
            switchTab(item) {
                this.filterValue = item;
            },
            LoadData() {
                fetch('/api/SessionMembers/Detail/' + this.SessionMemberId, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    if (res.ok) {
                        return res.json();
                    }
                    else if (res.status === 404) {
                        this.$router.push('/Historical/Legislator/Profile/' + this.SessionMemberId);///Legislators/Profile/1695/Detail
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => 
                {
                    this.loading = false;
                    if (response)
                    {
                        let r = EncodeDatetimes(response);

                        (r.Bills || []).forEach(x =>
                        {
                            x.BillURL = x.BillType + ' ' + x.BillNumberOnly;
                        });

                        this.Member = r;
                    }
                }).catch(error => {
                    throw new Error(error);
                    this.loading = false;
                });
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DateTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('M/DD/YYYY h:mm A');
                } else {
                    return null;
                }
            }
        },
        data: function () {
            return {
                loading: true,
                filterValue: 'Detail',
                tabs: ['Detail', 'Committees', 'Bills', 'Hearings'],
                Member: {
                    Session: {
                        Year: null
                    },
                },
                headers: [
                    {
                        text: 'Name',
                        value: 'Name',
                        type: 'Session',
                    },
                    {
                        text: 'Name',
                        value: 'Name',
                        type: 'Interim',
                    },
                    {
                        text: 'Name',
                        value: 'Name',
                        type: 'Conference',
                    },
                    {
                        text: 'Chair/Vice Chair',
                        value: 'SessionCommittees.Description',
                        type: 'Session',
                    },
                    {
                        text: 'Chair/Vice Chair',
                        value: 'InterimYearCommittee.Description',
                        type: 'Interim',
                    },
                    {
                        text: 'Chair/Vice Chair',
                        value: 'ConferenceCommittee.Description',
                        type: 'Conference',
                    },
                    {
                        text: 'Days',
                        value: 'SessionCommittees.Days',
                        type: 'Session',
                    },
                    {
                        text: 'Time',
                        value: 'SessionCommittees.MeetingTime',
                        type: 'Session',
                    },
                    {
                        text: 'Room',
                        value: 'SessionCommittees.Room',
                        type: 'Session',
                    },
                ],
                Billheaders: [
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'BillURL',
                    },
                    {
                        text: 'BillType',
                        value: 'Bill.BillType',
                    },
                    {
                        text: 'Bill',
                        align: 'start',
                        value: 'Bill.BillNumberOnly',
                    },
                    {
                        text: 'Title',
                        value: 'Bill.Title'
                    },
                ],
                HearingHeaders: [
                    {
                        text: 'Date',
                        value: 'ActionDate',
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                    },
                    {
                        text: 'Bill Type',
                        value: 'BillType',
                    },
                    {
                        text: 'Bill Number',
                        value: 'BillNumberOnly',
                    },
                    {
                        text: 'Committee',
                        value: 'FullName',
                    },

                ],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            SessionMemberId: {
                handler() {
                    this.LoadData();
                },
            },
        },
        computed: {
            SessionCommitteeHeaders: function () {
                return this.headers.filter(x => x.type == 'Session');
            },
            InterimCommitteeHeaders: function () {
                return this.headers.filter(x => x.type == 'Interim');
            },
            ConferenceCommitteeHeaders: function () {
                return this.headers.filter(x => x.type == 'Conference');
            },
            filteredSessionCommittees: function () {
                return this.Member.Committees.filter(x => x.SessionCommitteeId != null && x.SessionCommittees.FullBody != true);
            },
            filteredInterimCommittees: function () {
                var test = this.Member.Committees.filter(x => x.InterimYearCommitteeId != null);
                return test;
            },
            filteredConferenceCommittees: function () {
                return this.Member.Committees.filter(x => x.ConferenceCommitteeId != null);
            },
            filterPrimeSponsor: function () {
                return this.Member.Bills.filter(x => x.SponsorType == 'P');
            },
            filterCoSponsor: function () {
                return this.Member.Bills.filter(x => x.SponsorType == 'C');
            },
            filterHearings: function () {
                return this.Member.Hearings;
            }
        }
    };
</script>
<style></style>
