<template>
    <v-card flat>
        <div class="headline">
            Letters Of Intent
        </div>
        <v-card-text>
            <FilterableDataTable :headers="headers"
                                 disable-pagination
                                 hide-default-footer
                                 show-expand
                                 @click:row="rowClick"
                                 :expand-on-click="true"
                                 item-key="LetterOfIntentId"
                                 :loading="loading"
                                 :items="items">
                <template v-slot:item.MostRecentLetterDate="{ item }">
                    <a v-if="item.MostRecentLetter" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.MostRecentLetter.DocumentType + '/' + item.MostRecentLetter.DocumentId + '.pdf'">{{DatePretty(item.MostRecentLetter.DocumentDate)}}</a>
                </template>
                <template v-slot:item.MostRecentReportDate="{ item }">
                    <a v-if="item.MostRecentReport" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.MostRecentReport.DocumentType + '/' + item.MostRecentReport.DocumentId + '.pdf'">{{DatePretty(item.MostRecentReport.DocumentDate)}}</a>
                </template>
                <template v-slot:item.NextDueDate="{ item }">
                    {{DatePretty(item.NextDueDate)}}
                </template>
                <template v-slot:item.Year="{ item }">
                    {{Year(item.Year)}}
                </template>
                <template v-slot:item.Title="{ item }">
                    {{item.Title || item.Description}}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                        <Documents v-if="item.Documents" :Documents="item.Documents" :disablePagination="true"
                                   Title="Documents" InDialog :LetterOfIntentHeaders="true">
                        </Documents>
                    </td>
                </template>
            </FilterableDataTable>
        </v-card-text>
    </v-card>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    const Documents = () => import("@/Documents/Documents.vue");
    import { DateTime } from 'luxon';

    export default {
        name: 'LettersOfIntent',
        components: {
            FilterableDataTable,
            Documents
        },
        methods: {
            LoadData() {
                this.loading = true;
                fetch('/api/Documents/LettersOfIntent', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.json();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    this.items = EncodeDatetimes(response);
                    this.loading = false;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            DatePretty(date) {
                if (date)
                    return DateTime.fromJSDate(date).toFormat('MM/dd/yyyy');
                return '';
            },
            Year(date) {
                if (date)
                    return DateTime.fromJSDate(date).toFormat('yyyy');
                return '';
            },
            rowClick(any, item) {
                item.expand(!item.isExpanded);
            },
        },
        data: function () {
            return {
                loading: false,
                headers: [
                    { text: 'Frequency', value: 'Frequency', width: '20ch' },
                    { text: 'Due', value: 'NextDueDate', width: '11ch' },
                    { text: 'Title', value: 'Title', width: '45ch' },
                    { text: 'Agency', value: 'Agency', width: '*' },
                    { text: 'Session Year', value: 'Year' },
                    { text: 'Letter', value: 'MostRecentLetterDate', width: '11ch' },
                    { text: 'Report', value: 'MostRecentReportDate', width: '11ch' },
                ],
                items: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        computed: {
            currentRoute: function () {
                return this.$route.path;
            },
        }
    };
</script>
<style></style>