var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"headline"},[_vm._v(" Letters Of Intent ")]),_c('v-card-text',[_c('FilterableDataTable',{attrs:{"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","show-expand":"","expand-on-click":true,"item-key":"LetterOfIntentId","loading":_vm.loading,"items":_vm.items},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.MostRecentLetterDate",fn:function(ref){
var item = ref.item;
return [(item.MostRecentLetter)?_c('a',{attrs:{"rel":"noopener","href":_vm.$MyLRCUrl.value + 'api/Documents/' + item.MostRecentLetter.DocumentType + '/' + item.MostRecentLetter.DocumentId + '.pdf'}},[_vm._v(_vm._s(_vm.DatePretty(item.MostRecentLetter.DocumentDate)))]):_vm._e()]}},{key:"item.MostRecentReportDate",fn:function(ref){
var item = ref.item;
return [(item.MostRecentReport)?_c('a',{attrs:{"rel":"noopener","href":_vm.$MyLRCUrl.value + 'api/Documents/' + item.MostRecentReport.DocumentType + '/' + item.MostRecentReport.DocumentId + '.pdf'}},[_vm._v(_vm._s(_vm.DatePretty(item.MostRecentReport.DocumentDate)))]):_vm._e()]}},{key:"item.NextDueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.DatePretty(item.NextDueDate))+" ")]}},{key:"item.Year",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Year(item.Year))+" ")]}},{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Title || item.Description)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[(item.Documents)?_c('Documents',{attrs:{"Documents":item.Documents,"disablePagination":true,"Title":"Documents","InDialog":"","LetterOfIntentHeaders":true}}):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }